import { useState } from "react";
import Swal from "sweetalert2";

export default function RegistrationForm() {
  const [fileName, setFileName] = useState("");
  const [profile_picture, setProfileImage] = useState("");
  const [base64, setBase64] = useState("");
  const [formData, setFormData] = useState({
    fullName: "",
    mobileNumber: "",
    photo: profile_picture, // This will be updated after file upload
    address: "",
    role: "Coach",
    gender: "Male",
    age: "",
    experience: "",
    major_tournaments: "",
    other_qualification:"",
    qualification: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // Track if the form is submitting

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setBase64(base64String); // Base64 string is set here
        const uploadFile = async () => {
          const url = "https://dev-wkl.indiawkl.com/";
          const token = "a5927acc859fecf:4806e10ab1f7bcd";
          const formData = new URLSearchParams();
          formData.append("cmd", "uploadfile");
          formData.append("doctype", "File");
          formData.append("docname", "LEAD-00149");
          formData.append("filename", fileName);
          formData.append("filedata", base64String); // base64 encoded content
          formData.append("from_form", "1");
          try {
            const response = await fetch(url, {
              method: "POST",
              headers: {
                Authorization: `token ${token}`,
                "Content-Type": "application/x-www-form-urlencoded",
                Cookie:
                  "full_name=Guest; sid=Guest; system_user=no; user_id=Guest; user_image=",
              },
              body: formData.toString(),
            });

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            setProfileImage(data.message.file_url);
            setFormData((prevState) => ({
              ...prevState,
              profile_picture: data.message.file_url,
            }));
          } catch (error) {
            console.error("Error:", error);
          }
        };

        uploadFile();
      };
      reader.readAsDataURL(file); // Convert file to Base64
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true); // Disable the button while submitting

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "full_name=Guest; sid=Guest; system_user=yes; user_id=Guest; user_image="
    );

    const raw = JSON.stringify({
      full_name: formData.fullName,
      mobile_number: formData.mobileNumber || null,
      profile_image: profile_picture || null, // Use updated state
      role: formData.role || null,
      experience: formData.experience || null,
      gender: formData.gender || null,
      qualification: formData.qualification || null,
      major_tournaments: formData.major_tournaments || null,
      other_qualification:  formData.other_qualification || null,  
      age: formData.age || null,
      _user_tags: null,
      _comments: null,
      _assign: null,
      _liked_by: null,
    });

    try {
      const response = await fetch(
        "https://dev-wkl.indiawkl.com/api/resource/WKL%20Form?fields=[%22*%22]",
        {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        }
      );
      const result = await response.text();
      console.log(result);
      Swal.fire("Success", "Your registration was successful!", "success");

      // Disable the submit button after successful submission
      setIsSubmitting(false);
      // Refresh the page
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
      Swal.fire("Error", "Something went wrong. Please try again.", "error");

      // Re-enable the button if there was an error
      setIsSubmitting(false);
    }
  };

  return (
    <section id="registration-form">
      <h5>Registration Form </h5>
      <h6>Coaches and Officials</h6>
      <div className="container formnew mt-5">
        <div className="card wizard-content p-4 shadow">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 col-12">
                <label className="form-label">Full Name</label>
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="col-md-6 col-12">
                <label className="form-label">Mobile Number</label>
                <input
                  type="tel"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  maxLength={10}
                  minLength={10}
                  className="form-control"
                  required
                />
              </div>
              <div className="col-md-6 col-12">
                <label className="form-label">Photo</label>
                <input
                  type="file"
                  id="fileInput"
                  className="form-control"
                  onChange={handleFileChange}
                  accept="image/png, image/jpeg"
                  required
                />
              </div>
              <div className="col-md-6 col-12">
                <label className="form-label">Address</label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="col-md-4 col-12">
                <label className="form-label">Role</label>
                <select
                  name="role"
                  value={formData.role}
                  onChange={handleChange}
                  className="form-select"
                >
                  <option value="" disabled selected>Select your option</option>
                  <option value="Coach">Coach</option>
                  <option value="Official">Official</option>
                </select>
              </div>
              <div className="col-md-4 col-12">
                <label className="form-label">Gender</label>
                <select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="form-select"
                >
                  <option value="" disabled selected>Select your option</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="col-md-4 col-12">
                <label className="form-label">Age</label>
                <input
                  type="number"
                  name="age"
                  value={formData.age}
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </div>

              <div className="col-md-6 col-12">
                <label className="form-label">Qualification NIS</label>
                <select
                  name="qualification"
                  value={formData.qualification}
                  onChange={handleChange}
                  className="form-select"
                >
                  <option value="" disabled selected>Select your option</option>
                  <option value="Short">Short</option>
                  <option value="Long">Long</option>
                </select>
              </div>

              <div className="col-md-6 col-12">
                <label className="form-label">Major Tournaments if any</label>
                <input
                  type="text"
                  name="major_tournaments"
                  value={formData.major_tournaments}
                  onChange={handleChange}
                  className="form-control"
                  required
                />
              </div>

              <div className="col-md-12 col-12">
                <label className="form-label">Other Qualification</label>
                <input
                  type="text"
                  name="other_qualification"
                  value={formData.other_qualification}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>

              <div className="col-md-12 col-12">
                <label className="form-label">Experience</label>
                <textarea
                  name="experience"
                  value={formData.experience}
                  onChange={handleChange}
                  className="form-control"
                  required
                ></textarea>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-primary w-100"
              disabled={isSubmitting} // Disable button if submitting
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}
