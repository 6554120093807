import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import abstract from '../arrow.gif'
import abstract1  from '../arrowleft.gif'
export default function Fixture() {
  const options = {
    loop: true,
    margin:20,
    nav: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    items: 3.2, // Number of items to display
    responsive: {
        0: {
          margin:10,
          items: 1.1, // 1 item for small screens
        },
        600: {
          items: 3.2, // 2 items for medium screens
        },
        1000: {
          items:3.2, // 3 items for large screens
        },
      },


  };
  return (
    <>
      <section id="fixture">

        <div className="heading-wkl">
            <h3><img src={abstract1} className="img-fluid arrowabtract" />&nbsp;Fixtures &nbsp;<img src={abstract} className="img-fluid arrowabtract" /></h3>
        </div>
        <div className="container-fluid">
          <div className="row">
            <OwlCarousel className="owl-theme p-0" {...options}>

              <div className="fixture-card">
                <div className="row">
                  <div className="col-5">
                    <div className="left-fixture">
                      <img
                        src="https://dev-ipkl.ipkl.in//private/files/TBD.png"
                        className="img-fluid"
                      />
                      <h4>TEAM A</h4>

                      <h3>--</h3>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="center-fixture">
                       <div className="date">
                       <h5>DAY : XX</h5>
                       <h6>Coming Soon</h6>

                       <div className="vs">
                        VS
                       </div>
                       </div>

                    </div>
                  </div>

                  <div className="col-5">
                    <div className="left-fixture">
                      <img
                        src="https://dev-ipkl.ipkl.in//private/files/TBD.png"
                        className="img-fluid"
                      />
                      <h4>Team B</h4>
                      <h3>--</h3>

                    </div>
                  </div>
                </div>

                <div className="row">
                    <div className="venue">
                        <p>Time: XX:00PM - XX:00 PM</p>
                        {/* <p>Stadium </p> */}
                    </div>
                </div>
              </div>

            </OwlCarousel>
          </div>
        </div>
      </section>
    </>
  );
}
